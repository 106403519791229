<template>
  <a-spin :spinning="isLoading" size="large">
    <div class="flex h-full w-full">
      <div class="block xs:flex items-center justify-center m-auto w-full">
        <div class="py-20">
          <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
              mode="out-in"
          >
            <div v-if="!errorValue && !hasPassword && !showWelcomeScreen && Object.keys(survey).length > 0">
              <div>
                <div
                    v-show="currentQuestionIndex !== 0 && !isFinished"
                    class="btn-action_position btn-action_position-left"
                >
                  <a-tooltip placement="topRight"
                             :title="survey.translations.surveyNav.tooltipBack.active && !isMobileDevice ? survey.translations.surveyNav.tooltipBack.value || survey.translations.surveyNav.tooltipBack.defaultValue : null"
                  >
                    <a-button
                        type="default"
                        shape="circle"
                        icon="arrow-left"
                        size="large"
                        class="btn-action"
                        :style="hoveredBtnBack ? accentColor : ''"
                        @mouseover="hoveredBtnBack = true"
                        @mouseout="hoveredBtnBack = false"
                        @click="prevQuestion"
                    />
                  </a-tooltip>
                </div>
                <div v-show="currentQuestionIndex < questionsCount && !isFinished">
                  <transition-group
                      name="fade"
                      tag="div"
                      enter-class="absolute"
                      enter-active-class="animate__animated animate__fadeIn animate__delay-1s"
                  >
                    <div
                        v-for="(question, index) in computedQuestions"
                        :key="question._id"
                        v-show="index === currentQuestionIndex"
                        class="w-full">
                      <component
                          :is="questionTypes[question.type].component"
                          :question="question"
                          :isVisible="index === currentQuestionIndex"
                          @onAnswer="onAddAnswer"
                      />
                    </div>
                  </transition-group>
                </div>
                <VComment
                    v-if="currentQuestionIndex >= questionsCount && !isFinished && survey.comment.active"
                    @onFinishSurvey="finishSurvey"
                />
                <VThankYouScreen
                    v-else-if="isFinished"
                />
              </div>
              <div
                  v-if="!isFinished"
                  class="progress-wrapper"
              >
                <a-progress
                    :percent="progressPercentage"
                    :format="() => ''"
                    :stroke-color="accentColor.color"
                />
              </div>
            </div>
            <VError
                v-else-if="errorValue"
                :type="errorValue"
                :translations="translations"
            />
            <VPassword
                v-else-if="hasPassword"
                :error-text="passwordError"
                :translations="translations"
                @onPassword="fetchSurvey"
            />
            <VWelcomeScreen
                v-else-if="showWelcomeScreen"
                @onStart="showWelcomeScreen = false"
            />
          </transition>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import _ from "lodash";
import VComment from "@/components/VComment";
import VWelcomeScreen from "@/components/VWelcomeScreen";
import VThankYouScreen from "@/components/VThankYouScreen";
import VSwipeCard from "@/components/VSwipeCard";
import VMultiple from "@/components/VMultiple";
import VNps from "@/components/VNps";
import VRating from "@/components/VRating";
import VOpen from "@/components/VOpen";
import {CREATE_RESPONSE, FETCH_SURVEY} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {Spin} from 'ant-design-vue';
import VError from "@/components/VError";
import VPassword from "@/components/VPassword";
import {getUrlParam, listUrlParamsStr, setBackground, shuffle, surveyPostMessage} from "../helpers/fns";
import {evaluateCondition} from "../helpers/questionLogic";
import themeMixin from "../mixins/theme.mixin";

const isPreviewMode = getUrlParam('preview_mode') === 'true';
const typeGetResponded = "ms-survey-get-responded";
const typeSetResponded = "ms-survey-set-responded";

export default {
  name: "VSurvey",
  components: {VComment, VThankYouScreen, 'a-spin': Spin, VError, VPassword, VWelcomeScreen},
  mixins: [themeMixin],
  created() {
    this.fetchSurvey();

    window.addEventListener('beforeunload', this.onHandleClose)
    window.addEventListener('message', this.handleIframeMessage)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onHandleClose)
    window.removeEventListener('message', this.handleIframeMessage)
  },
  data() {
    return {
      questionTypes: {
        swipe: {component: VSwipeCard, titleClass: '', hasTitle: false},
        multiple: {component: VMultiple, titleClass: 'mb-6 md:mb-7', hasTitle: true},
        nps: {component: VNps, titleClass: 'mb-8 md:mb-12', hasTitle: true},
        rating: {component: VRating, titleClass: 'mb-8 md:mb-12', hasTitle: true},
        open: {component: VOpen, titleClass: '', hasTitle: false},
      },
      currentQuestionIndex: 0,
      questionIndexHistory: [],
      answers: [],
      errorValue: '',
      hasPassword: false,
      passwordError: '',
      progressPercentage: 0,
      translations: {},
      isIframe: window.self !== window.top,
      hoveredBtnBack: false,
      hoveredBtnFinish: false,
    }
  },
  computed: {
    ...mapGetters(["survey", "questions", "questionsCount", "currentThankyouScreen", "isLoading", "isFinished"]),
    isMobileDevice: function () {
      return window.outerWidth <= process.env.VUE_APP_MOBILE_WIDTH_BREAKPOINT
    },
    showWelcomeScreen: {
      get: function () {
        return this.survey.welcomeScreen?.isActive;
      },
      set: function (newState) {
        this.survey.welcomeScreen.isActive = newState;
      }
    },
    computedQuestions: function () {
      //Check for questions randomization
      if (this.survey && this.survey.randomize_questions) {
        shuffle(this.questions);
      }
      return this.questions;
    }
  },
  methods: {
    async fetchSurvey(password = null) {
      const {survey_id} = this.$route.params;
      try {
        await this.$store.dispatch(FETCH_SURVEY, {survey_id, password});

        this.hasPassword = false;

        await this.checkForCustomDomain();

        this.checkForMultipleResponses();

        this.setWindowTitle();

        this.setSurveyTheme();

      } catch (e) {
        this.handleSurveyError(e);
      }
    },
    onAddAnswer({question_id, value}) {
      this.answers.push({question_id, value});
      if (this.currentQuestionIndex < this.questionsCount) {
        this.goToNextQuestion(value);
      }
      this.calculateProgress();

      //Check if comment is active or finish the survey
      if (this.currentQuestionIndex >= this.questionsCount && !this.isFinished && !this.survey.comment.active) {
        this.finishSurvey();
      }

      //Scroll to top to display question on the right place
      this.scrollToTop();
    },
    prevQuestion() {
      this.goToPrevQuestion();
      this.answers.pop();
      this.calculateProgress();

      //Scroll to top to display question on the right place
      this.scrollToTop();
    },
    finishSurvey(comment = '', isFinished = true, thankyouScreenId = null) {
      const data = {
        survey_id: String(this.survey._id),
        responses: this.answers,
        isFinished,
        comment,
        urlParamsStr: listUrlParamsStr(),
        thankyouScreenId,
      }
      this.$store.dispatch(CREATE_RESPONSE, {...data})
          .then(() => {
            // Send message to the parent document (callback for iframe)
            surveyPostMessage("ms-survey-completed", {...data})

            this.markSurveyAsResponded();

            this.handleSurveyRedirect();
          })
          .catch((error) => {
            if (error && error.code) {
              this.$message.error("Survey submission failed. Please try again!");
            }
            console.error(error);
          });
    },
    calculateProgress() {
      this.progressPercentage = parseFloat((this.currentQuestionIndex / this.questionsCount * 100).toFixed(0));
    },
    onHandleClose(e) {
      if (!this.isFinished && this.answers.length > 0 && !this.isIframe) {
        e.preventDefault();
        e.returnValue = 'Are you sure? Your answers will be lost.';
        return false;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    checkForCustomDomain() {
      return new Promise(resolve => {

        if (location.hostname === process.env.VUE_APP_DEFAULT_DOMAIN) return resolve(true);

        if (this.survey.custom_domain.active && location.hostname.toLowerCase() === this.survey.custom_domain.verifiedDomain.toLowerCase()) return resolve(true);

        // redirect to public domain
        location.href = `https://${process.env.VUE_APP_DEFAULT_DOMAIN}/survey/${this.survey._id}/`;

      })
    },
    goToNextQuestion(answerValue) {
      const question = this.questions[this.currentQuestionIndex];
      const logics = question.logics;

      // Set navigation history
      this.questionIndexHistory.push(this.currentQuestionIndex);

      if (logics && logics.length) {
        // Filter only active logics
        const activeLogics = logics.filter(logic => logic.active);

        for (const logic of activeLogics) {
          // Evaluate conditions with "and" or "or" operators
          const hasTrueCondition = logic.conditions.reduce((result, condition, index) => {
            if (index === 0) {
              return evaluateCondition(condition.trigger, answerValue, condition.value);
            } else {
              if (condition.operator === "and") {
                return result && evaluateCondition(condition.trigger, answerValue, condition.value);
              } else if (condition.operator === "or") {
                return result || evaluateCondition(condition.trigger, answerValue, condition.value);
              } else {
                return result; // Handle invalid operator cases
              }
            }
          }, false);

          // Navigate to the question
          if (hasTrueCondition && logic.action.type === "goto") {
            const questionId = logic.action.value;

            if (questionId === "lastStepComment") {
              this.currentQuestionIndex = this.questionsCount;
            } else if (questionId?.includes("thankyou")) {
              const thankyouScreenId = questionId.split("-")[1];
              this.finishSurvey("", true, thankyouScreenId);
            } else if (questionId) {
              this.currentQuestionIndex = _.findIndex(this.questions, {_id: questionId});
            } else {
              this.finishSurvey();
            }

            return;
          }

        }
      }

      // Go to the next question if all logics false
      this.currentQuestionIndex += 1;
    },
    goToPrevQuestion() {
      if (this.questionIndexHistory.length > 0) {
        this.currentQuestionIndex = this.questionIndexHistory.pop();
        return;
      }

      this.currentQuestionIndex -= 1;
    },
    checkForMultipleResponses() {
      if (this.survey.multiple_responses) return;

      const key = `ms-${this.survey._id}`;
      let isResponded = false;

      if (this.isIframe) {
        surveyPostMessage(typeGetResponded, {key});
        return;
      }

      try {
        isResponded = window.localStorage.getItem(key)
      } catch (e) {
        return;
      }

      if (!isPreviewMode && isResponded) {
        this.errorValue = "responded"
      }
    },
    markSurveyAsResponded() {
      const key = `ms-${this.survey._id}`;
      const answers = JSON.stringify(this.answers);

      if (this.isIframe) {
        surveyPostMessage(typeSetResponded, {key, answers});
        return;
      }

      if (!isPreviewMode) {
        try {
          window.localStorage.setItem(key, answers)
        } catch (error) {
          return;
        }
      }
    },
    setWindowTitle(value = undefined) {
      if (this.isIframe) return;

      if (value) {
        window.document.title = value;
        return;
      }

      const {title, hasBranding} = this.survey;
      const defaultTitle = "Survey";
      window.document.title = hasBranding ? `${title || defaultTitle} – ${process.env.VUE_APP_BASE_TITLE}` : title || defaultTitle;
    },
    setSurveyTheme(data = this.survey) {
      if (!data?.theme?.settings?.bgColor) return;

      setBackground(data.theme.settings.bgColor);
    },
    handleSurveyRedirect() {
      if (this.currentThankyouScreen && this.currentThankyouScreen.redirect.isActive && this.currentThankyouScreen.redirect.link) {
        const redirectFunction = this.isIframe
            ? () => surveyPostMessage("ms-survey-redirect", {url: this.currentThankyouScreen.redirect.link})
            : () => (window.top.location.href = this.currentThankyouScreen.redirect.link);

        setTimeout(redirectFunction, process.env.VUE_APP_REDIRECT_TIMEOUT);
      }
    },
    handleSurveyError(e) {
      let {code, type, data} = e;
      let documentTitle = 'Survey';

      switch (code?.toString()) {
        case '422':
          if (type === 'NOT_AVAILABLE') {
            this.errorValue = 'notAvailable';
            documentTitle = 'Survey paused';
          } else if (type === 'RESPONSES_LIMIT_EXCEEDED') {
            this.errorValue = 'responsesLimitExceeded';
            documentTitle = 'Survey closed';
          } else if (type === 'NEED_PASSWORD') {
            this.hasPassword = true;
            documentTitle = 'Enter survey password';
          } else if (type === 'WRONG_PASSWORD') {
            this.hasPassword = true;
            this.passwordError = data.translations.surveyPassword.error.value || data.translations.surveyPassword.error.defaultValue;
            documentTitle = 'Enter survey password';
          }

          this.setWindowTitle(documentTitle);

          this.setSurveyTheme(data);

          // Set translations
          if (typeof data.translations === "object") {
            this.translations = data.translations;
          }

          break;
        case '404':
          this.errorValue = 'notFound';
          this.setWindowTitle('Survey not found');
          break;
        default:
          this.errorValue = 'other';
      }
    },
    handleIframeMessage(event) {
      const {type, data} = event.data;

      if (type === typeGetResponded && data && data.isResponded) {
        this.errorValue = "responded";
      }
    },
  }
}
</script>

<style lang="less" scoped>
.btn-action_position {
  @v-btn-action-side-position: 24px;
  position: fixed;
  top: 12px;
  z-index: 999;

  &-right {
    right: @v-btn-action-side-position;
  }

  &-left {
    left: @v-btn-action-side-position;
  }
}

.progress-wrapper {
  max-width: 100vw;
  width: 100%;
  position: fixed;
  top: -12px;
  left: 0;
}

@media @v-mobile-break-point {
  .btn-action_position {
    @v-btn-action-side-position: 16px;
    position: fixed;
    top: calc(50% - @v-btn-height-lg / 2);
    z-index: 999;

    &-right {
      right: @v-btn-action-side-position;
    }

    &-left {
      left: @v-btn-action-side-position;
    }
  }
}
</style>
