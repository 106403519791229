import _ from "lodash";

export function evaluateCondition(trigger, answerValue, value) {
  switch (trigger) {
    case "=":
      return +answerValue === +value;
    case "≠":
      return +answerValue !== +value;
    case ">":
      return +answerValue > +value;
    case "<":
      return +answerValue < +value;
    case "≥":
      return +answerValue >= +value;
    case "≤":
      return +answerValue <= +value;
    case "is empty":
      return _.isEmpty(answerValue);
    case "is not empty":
      return !_.isEmpty(answerValue);
    case "is":
      return answerValue?.toLowerCase().trim() === value?.toLowerCase().trim();
    case "is not":
      return answerValue?.toLowerCase().trim() !== value?.toLowerCase().trim();
    case "contains":
      if (_.isString(answerValue)) {
        answerValue = answerValue?.toLowerCase().trim()
        value = value?.toLowerCase().trim()
      }
      return _.includes(answerValue, value);
    case "does not contain":
      if (_.isString(answerValue)) {
        answerValue = answerValue?.toLowerCase().trim()
        value = value?.toLowerCase().trim()
      }
      return !_.includes(answerValue, value);
    case "begins with":
      return _.startsWith(answerValue?.toLowerCase().trim(), value?.toLowerCase().trim());
    case "ends with":
      return _.endsWith(answerValue?.toLowerCase().trim(), value?.toLowerCase().trim());
    default:
      return false;
  }
}
