<template>
  <VCard>
    <template #title>
      <VCollapsedText :title="question.text" class="text-xl leading-2 font-normal mb-4" :style="textColor"/>
    </template>

    <a-form :form="form"
            @submit="onNext"
            class="open-answer flex flex-grow flex-col justify-between"
    >
      <a-form-item class="flex-none"
                   :validate-status="hasError() ? 'error' : ''"
                   :help="hasError() || ''"
      >
        <a-input-group v-if="question.subtype === 'phone'" compact>
          <a-select show-search
                    size="large"
                    v-model="countryCode"
                    :autoFocus="true"
                    :filterOption="filterOption"
                    :dropdownMatchSelectWidth="false"
                    option-label-prop="label"
                    style="height: 52px; width: 33%;"
          >
            <a-select-option v-for="code in phoneCodes"
                             :key="code.code"
                             :value="code.code"
                             :label="`${code.flag} ${code.dial_code}`"
            >
              {{ `${code.flag} ${code.name} ${code.dial_code}` }}
            </a-select-option>
          </a-select>
          <a-input :placeholder="defaultMessages.placeholder[question.subtype]"
                   size="large"
                   ref="input"
                   style="height: 52px; width: 67%; border-top-right-radius: 10px; border-bottom-right-radius: 10px; text-align: left;"
                   v-decorator="['open', { ...rules[question.subtype] }]"
          />
        </a-input-group>
        <a-textarea v-else-if="question.subtype === 'multiLine'"
                    :placeholder="question.options.placeholder || defaultMessages.placeholder[question.subtype]"
                    :autoSize="{minRows: 4}"
                    ref="input"
                    v-decorator="['open', { ...rules[question.subtype] }]"
        ></a-textarea>
        <a-input v-else
                 :placeholder="question.options.placeholder || defaultMessages.placeholder[question.subtype]"
                 size="large"
                 ref="input"
                 style="height: 52px;"
                 v-decorator="['open', { ...rules[question.subtype] }]"
        >
          <a-icon v-if="question.subtype === 'email'" slot="prefix" type="mail"
                  style="font-size:24px; color: rgba(154, 165, 187, 0.5);"/>
        </a-input>
      </a-form-item>
      <a-form-item class="text-center" style="margin-bottom: 0;">
        <a-button
            type="primary"
            size="large"
            html-type="submit"
            class="btn-action-primary"
            :style="[buttonBgColor, buttonTextColor]"
            :disabled="hasErrors(form.getFieldsError())"
        >
          {{
            question.options.btnLabel || survey.translations.questions.open.buttonSubmit.value || survey.translations.questions.open.buttonSubmit.defaultValue
          }}
        </a-button>
      </a-form-item>
    </a-form>
  </VCard>
</template>

<script>
import questionMixin from "../mixins/question";
import hasErrors from "@/helpers/formValidate";
import PHONE_CODES from "../helpers/phoneCodes";
import {find} from "lodash";
import {mapGetters} from "vuex";
import themeMixin from "../mixins/theme.mixin";
import VCollapsedText from "./VCollapsedText";
import VCard from "./VCard";

export default {
  name: "VOpen",
  mixins: [questionMixin, themeMixin],
  components: {VCard, VCollapsedText},
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },
  data() {
    return {
      hasErrors,
      form: this.$form.createForm(this, {name: 'question_open'}),
      phoneCodes: PHONE_CODES,
      countryCode: 'US',
    }
  },
  computed: {
    ...mapGetters(["survey"]),
    defaultMessages: function () {
      return {
        placeholder: {
          singleLine: "Type answer here",
          multiLine: "Type answer here",
          number: "Type number here",
          email: "you@yourcompany.com",
          phone: "Type phone number",
        },
      }
    },
    errorMsg: function () {
      return {
        singleLine: this.survey.translations.questions.open.error.value || this.survey.translations.questions.open.error.defaultValue,
        multiLine: this.survey.translations.questions.open.error.value || this.survey.translations.questions.open.error.defaultValue,
        number: this.survey.translations.questions.openNumber.error.value || this.survey.translations.questions.openNumber.error.defaultValue,
        email: this.survey.translations.questions.openEmail.error.value || this.survey.translations.questions.openEmail.error.defaultValue,
        phone: this.survey.translations.questions.openPhone.error.value || this.survey.translations.questions.openPhone.error.defaultValue,
      }
    },
    rules: function () {
      return {
        singleLine: {rules: [{required: this.question.options.required, message: this.errorMsg.singleLine}]},
        multiLine: {rules: [{required: this.question.options.required, message: this.errorMsg.multiLine}]},
        number: {
          rules: [{
            required: this.question.options.required,
            message: this.errorMsg.number,
            pattern: new RegExp(/^[-]?\d+(\.\d+)?$/)
          }]
        },
        email: {rules: [{required: this.question.options.required, message: this.errorMsg.email, type: 'email'}]},
        phone: {
          rules: [{
            required: this.question.options.required,
            message: this.errorMsg.phone,
            pattern: new RegExp(/^\d+$/)
          }]
        },
      }
    },
  },
  methods: {
    // Only show error after a field is touched.
    hasError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('open') && getFieldError('open');
    },

    getPhoneCode() {
      const phoneCodeItem = find(this.phoneCodes, ['code', this.countryCode]);

      return `${phoneCodeItem.flag} ${phoneCodeItem.dial_code}`;
    },

    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    onNext(e) {
      if (e) e.preventDefault();

      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }

        let {open} = values;

        if (open === undefined) {
          open = '';
        }

        if (open && this.question.subtype === 'phone') {
          open = this.getPhoneCode() + open;
        }

        setTimeout(() => this.onAnswer(open), process.env.VUE_APP_NEXT_QUESTION_DELAY);
      });
    },
  }
}
</script>

<style lang="less" scoped>

</style>
